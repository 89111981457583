/* eslint-disable no-duplicate-case */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useItemDataContext } from "../../helper/context/ItemDataContext";
import { Tooltip, Typography } from "@mui/material";
import { useAdvancedSearchContext } from "../../helper/context/AdvanceSearchContext";
import { useAdvancedSearchHitsContext } from "../../helper/context/AdvanceSearchHitsContext";
import { useSearchContext } from "../../helper/context/SearchTermContext";
import { useLocation } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dict2get from "../../helper/dict2get";
import AdvanceSearchModel from "../AdvanceSearchModel/AdvanceSearchModel";
import config from "../../helper/config";
import "./AdvanceSearch.css";

/**
 * Component: AdvancedSearchDialog
 * Description: This component represents a dialog for advanced search options.
 * It includes form fields for selecting search categories and entering search values.
 */

// Style the dialog components using MUI styling system
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// Constants for styling the select menu
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AdvancedSearchDialog() {
  // State variables initialization
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const { appliedFilters, setAppliedFilters } = useAdvancedSearchContext();
  const [headers, setHeaders] = useState([]);
  // State variable to store predictive results
  const [predictiveResults, setPredictiveResults] = useState([]);
  const { setSearchTerm } = useSearchContext();
  const [resetKey, setResetKey] = useState(0); // Added state for resetting Autocomplete

  const { setTotalHitsAdvanced } = useAdvancedSearchHitsContext();

  // Custom hook to access item data context
  const { setItemData } = useItemDataContext();

  const apiUrl = config.APIUrl + "query";
  const apiUrlLookup = config.APIUrl + "lookup";
  let navigate = useNavigate();
  const location = useLocation();

  // Function to convert header names to more appealing header names
  const headerToHeaderName = (header) => {
    switch (header) {
      case "family":
        return "Family";
      case "genus":
        return "Genus";
      case "specificEpithet":
        return "Species";
      case "catalogNumber":
        return "Catalog Number";
      case "infraspecificEpithet":
        return "Infraspecies";
      case "identifiedBy":
        return "Determiner";
      case "dateIdentified":
        return "det. Date";
      case "establishmentMeans":
        return "Native";
      case "typeStatus":
        return "Type";
      case "recordedBy":
        return "Collector";
      case "recordNumber":
        return "Collector Number";
      case "eventDate":
        return "Collection Date";
      case "country":
        return "Country";
      case "county":
        return "Subdivision/County";
      case "stateProvince":
        return "State/Province";
      case "locality":
        return "Locality";
      case "sex":
        return "Sex";
      case "habitat":
        return "Habitat";
      case "occurrenceRemarks":
        return "Habitat and Notes";
      case "reproductiveCondition":
        return "Reproductive Status";
      default:
        return header;
    }
  };
  // Effect hook to fetch initial data on component mount
  useEffect(() => {
    const performInitialLoad = () => {
      fetch(
        apiUrl + "?q=" + dict2get({ query: { match_all: {} }, size: 1000 }),
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          mode: "cors",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch initial data");
          }
          return response.json();
        })
        .then((data) => {
          const headers = Object.keys(data.hits.hits[0]._source);

          const filteredHeaders = headers.filter(
            (header) => headerToHeaderName(header) !== header
          );

          // Define the categories to prioritize
          const priorityCategories = ["genus", "specificEpithet", "family"];

          // Separate the priority categories from the rest
          const otherHeaders = filteredHeaders.filter(
            (header) => !priorityCategories.includes(header)
          );
          // Sort the remaining headers alphabetically
          const sortedHeaders = otherHeaders.sort((a, b) =>
            headerToHeaderName(a).localeCompare(headerToHeaderName(b))
          );

          // Combine the priority categories with the sorted headers
          const finalHeaders = [...priorityCategories, ...sortedHeaders];

          setHeaders(finalHeaders);
        })
        .catch((error) => {
          console.error("Error fetching initial data:", error);
        });
    };

    performInitialLoad();
  }, []);

  // Effect hook to fetch predictive results based on selected category
  useEffect(() => {
    const fetchPredictiveResults = () => {
      if (selectedCategory && selectedValue) {
        fetch(
          `${apiUrlLookup}?column=${selectedCategory}&prefix=${selectedValue}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            mode: "cors",
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch predictive results");
            }

            return response.json();
          })
          .then((data) => {
            setPredictiveResults(data); // Update to set predictiveResults directly
          })
          .catch((error) => {
            console.error("Error fetching predictive results:", error);
          });
      }
    };

    fetchPredictiveResults();
  }, [selectedCategory, selectedValue]);

  // Effect to clear applied filters when navigating away from the result page
  useEffect(() => {
    if (!location.pathname.startsWith("/result")) {
      setAppliedFilters([]);
    }
  }, [location.pathname, setAppliedFilters]);

  // Event handler for select input change
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  // Event handler for text field change
  const handleValueChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Event handler for adding filter
  const handleAddFilter = () => {
    if (selectedCategory && selectedValue) {
      setAppliedFilters([
        ...appliedFilters,
        { category: selectedCategory, value: selectedValue },
      ]);

      setSelectedCategory("");
      setSelectedValue("");
      setResetKey(resetKey + 1); // Force re-render by updating resetKey
    }
  };

  // Event handler for removing filter
  const handleRemoveFilter = (index) => {
    setAppliedFilters(appliedFilters.filter((_, i) => i !== index));
  };
  // Event handler for search and dialog action
  const handleSearchAndDialog = (e) => {
    e.preventDefault();
    try {
      // Call the update function with the constructed query
      update({
        query: {
          bool: {
            must: appliedFilters.map((filter) => ({
              match: { [filter.category]: filter.value },
            })),
          },
        },
        size: 1000,
      });
      setSearchTerm("");
    } catch (error) {
      console.error("Error performing search:", error);
    }
  };
  // Function to update data based on search query
  const update = (query = {}) => {
    query.size = 1000;

    return fetch(apiUrl + "?q=" + dict2get(query), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        const totalHits = data.hits.total.value; // Extract total hits value

        const processedData = data.hits.hits.map((hit) => ({
          _id: hit._id,
          ...hit._source,
        }));

        // Construct the search term
        const searchTerm = appliedFilters
          .map((filter) => `${filter.category}:${filter.value}`)
          .join(" AND ");

        // Navigate to the search result page
        navigate(`/result/${searchTerm}`);

        // Close the dialog
        setOpen(false);

        // Process the fetched data (e.g., render or update state)

        setItemData(processedData);

        setTotalHitsAdvanced(totalHits);
      })
      .catch((error) => {
        // Handle the error appropriately (e.g., show an error message to the user)
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        throw error; // Rethrow the error
      });
  };
  // Event handler for opening the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  // Event handler for closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Advanced Search">
        <Button
          onClick={handleClickOpen}
          sx={{
            color: "#243b2f",
            fontFamily: "Inter-Medium",
          }}
        >
          Advanced
        </Button>
      </Tooltip>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: "#243b2f",
            color: "white",
            fontFamily: "Inter-Bold",
          }}
          id="customized-dialog-title"
        >
          Advanced Search
        </DialogTitle>
        <AdvanceSearchModel />
        <DialogContent dividers sx={{ backgroundColor: "white" }}>
          <Grid
            columnSpacing={{ xs: 1, sm: 3, md: 3 }}
            container
            spacing={3}
            columns={16}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 200 }}>
                <Typography>Category</Typography>
                <Select
                  labelId="demo-multiple-category-label"
                  id="demo-multiple-category"
                  value={selectedCategory}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                >
                  {headers.map((header) => (
                    <MenuItem key={header} value={header}>
                      {headerToHeaderName(header)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                key={resetKey}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      selectedCategory
                        ? headerToHeaderName(selectedCategory)
                        : "Value"
                    }
                    value={selectedValue}
                    onChange={handleValueChange}
                    variant="outlined"
                  />
                )}
                options={predictiveResults}
                sx={{
                  width: 250,
                  fontFamily: "Inter-Medium",
                  marginLeft: "10px",
                }}
                onChange={(event, newValue) => {
                  setSelectedValue(newValue); // Update selectedValue as user types
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Button
                className="Add-Button"
                onClick={handleAddFilter}
                style={{
                  marginLeft: "50px",
                  backgroundColor: "#243b2f",
                  color: "white",
                  fontFamily: "Inter-Medium",
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
          {/* Render applied filters */}
          <h4
            className="applied-filters"
            style={{ fontFamily: "Inter-Medium", marginTop: "10px" }}
          >
            Applied Filters
          </h4>
          <Stack direction="row" spacing={1}>
            {appliedFilters.map((filter, index) => (
              <Chip
                key={index}
                label={`${headerToHeaderName(filter.category)}: ${
                  filter.value
                }`}
                onDelete={() => handleRemoveFilter(index)}
                style={{
                  fontFamily: "Inter-Medium",
                }}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#243b2f" }}>
          <Button
            autoFocus
            onClick={handleClose}
            sx={{
              borderRadius: "20px",
              backgroundColor: "white",
              color: "#243b2f",
              "&:hover": {
                backgroundColor: "white",
              },
              fontFamily: "Inter-Medium",
            }}
          >
            Close
          </Button>
          <Button
            onClick={(e) => {
              handleSearchAndDialog(e);
            }}
            disabled={appliedFilters.length === 0}
            sx={{
              borderRadius: "20px",
              backgroundColor: "white",
              color: "#243b2f",
              "&:hover": {
                backgroundColor: "white",
              },
              fontFamily: "Inter-Medium",
            }}
          >
            Search
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
