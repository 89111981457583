import React, { createContext, useContext, useState } from "react";

// Create a context for advanced search
const AdvancedSearchHitsContext = createContext();
// Custom hook to access advance search context
export const useAdvancedSearchHitsContext = () =>
  useContext(AdvancedSearchHitsContext);

// Provider component to manage advance search hits state
export const AdvancedSearchHitsProvider = ({ children }) => {
  // State to manage total Hits for advanced search
  const [totalHitsAdvanced, setTotalHitsAdvanced] = useState(0);

  return (
    <AdvancedSearchHitsContext.Provider
      value={{ totalHitsAdvanced, setTotalHitsAdvanced }}
    >
      {children}
    </AdvancedSearchHitsContext.Provider>
  );
};
