/**
 * Function: dict2get
 * Description: This function converts a dictionary object into a URL-safe string representation,
 *              suitable for use in query parameters or URL paths.
 * @param {Object} data - The dictionary object to be converted
 * @returns {string} - The URL-safe string representation of the dictionary object
 */

export function dict2get(data) {
  const pako = require("pako");
  const Buffer = require("buffer").Buffer;
  const jsonStr = JSON.stringify(data);
  const compressedData = pako.deflate(jsonStr, { to: "string" });
  const base64Str = Buffer.from(compressedData).toString("base64");
  const urlSafeStr = encodeURIComponent(base64Str)
    .replaceAll("=", "_")
    .replaceAll("+", ".")
    .replaceAll("/", "-");
  return urlSafeStr;
}

export default dict2get;
