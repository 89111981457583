// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { ItemDataProvider } from "./helper/context/ItemDataContext";
import { SearchProvider } from "./helper/context/SearchTermContext";
import { AdvancedSearchProvider } from "./helper/context/AdvanceSearchContext";
import { AdvancedSearchHitsProvider } from "./helper/context/AdvanceSearchHitsContext";
import SearchMainPage from "./pages/SearchMainPage/SearchMainPage";
import SearchResultPage from "./pages/SearchResultPage/SearchResultPage";

// Main component of the application
const App = () => {
  return (
    // Provide data using ItemDataContext
    <ItemDataProvider>
      <SearchProvider>
        <AdvancedSearchHitsProvider>
          <AdvancedSearchProvider>
            <Router>
              <>
                <Routes>
                  <Route path="/" element={<SearchMainPage />} />
                  <Route
                    path={`/result/:searchTerm`}
                    element={<SearchResultPage />}
                  />
                </Routes>
              </>
            </Router>
          </AdvancedSearchProvider>
        </AdvancedSearchHitsProvider>
      </SearchProvider>
    </ItemDataProvider>
  );
};

export default App;
