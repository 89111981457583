import React from "react";
import ImageData from "../../components/ImageData/ImageData";
import NavBar from "../../components/NavBar/NavBar";
import "./SearchMainPage.css";

/**
 * SearchMainPage Component
 * Description: This component represents the main page of the search feature.
 * It includes the navigation bar and the search bar with image data.
 *
 */
const SearchMainPage = () => {
  return (
    <>
      <NavBar />
      <div className="searchbar-mainpage">
        <div className="searchbar-container">
          <h1 className="rbg-title">National Herbarium of New South Wales</h1>
          <h2 className="rbg-subtitle">Explore Our Collection</h2>
          <ImageData />
        </div>
      </div>
    </>
  );
};

export default SearchMainPage;
