import React from "react";
import { Link } from "react-router-dom";
import UserGuideModel from "../../components/UserGuideModel/UserGuideModel";
import "./NavBar.css";

/**
 * Component: NavBar
 * Description: This component represents the navigation bar of the application.
 * It includes the RBG logo and a link to the home page, as well as a user guide modal.
 */

const NavBar = () => {
  return (
    <nav className="nav">
      <Link to="/" className="rbg-logo">
        <img
          src="/images/BGS_Logo_Inline_Green-Light_RGB@2x.png"
          alt="Logo"
          className="navbar-logo"
        />
      </Link>
      <UserGuideModel />
    </nav>
  );
};

export default NavBar;
