import React, { useState } from "react";
import { BiHelpCircle } from "react-icons/bi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";

// Style for the modal content and container
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Set initial width as a percentage
  maxWidth: "600px", // Set maximum width to maintain readability
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "24px",
  boxShadow: 24,
  p: 4,
};
// Style for the content within the modal, including scrollbar
const contentStyle = {
  maxHeight: "400px", // Set the maximum height of the content container
  overflowY: "auto", // Add vertical scrollbar when content overflows
};
/**
 * Component: UserGuideModel
 * Description: This component represents a modal displaying a user guide.
 * It provides information about the platform's main functionality and instructions for users.
 */
const UserGuideModel = () => {
  const [open, setOpen] = useState(false);
  // Function to open the modal
  const handleOpen = () => setOpen(true);
  // Function to close the modal
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title="User Guide">
        <Button onClick={handleOpen} className="user-guide-icon">
          <BiHelpCircle id="guide-icon" size={22} style={{ color: "white" }} />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={contentStyle}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontFamily: "Inter-Medium" }}
            >
              User Guide
            </Typography>

            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              Welcome to the National Herbarium of New South Wales Collection
              Search Platform. This guide will help you get started with using
              the search and download functionality effectively.
            </Typography>

            <Typography
              variant="h6"
              component="h2"
              sx={{ mt: 4, fontFamily: "Inter-Medium" }}
            >
              Main Functionality
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              The National Herbarium of New South Wales Collection Search
              Platform provides users with the capability to search, view, and
              download images. Below are the key features:
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <ol>
                <li>
                  <strong>Search:</strong> Users can search and filter through
                  specimen records using our search functionality.
                </li>
                <li>
                  <strong>Advance Search:</strong> Users can apply more specific
                  filters using the advance search filter located on the search
                  bar.
                </li>
                <li>
                  <strong>View:</strong> Once you've performed a search, you can
                  view a summary of the specimen data and individual images.
                </li>
                <li>
                  <strong>Image Download:</strong> Users can download individual
                  images or select multiple images for batch download. There is
                  a limit to the amount of items selected for bulk download (20
                  images).
                </li>
                <li>
                  <strong>Data Download:</strong> Users can download specimen
                  data (including image links) to CSV if needing to search for a
                  large number of images at once. Free applications are
                  available online that will open/download images from a list of
                  image links.
                </li>
              </ol>
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              sx={{ mt: 4, fontFamily: "Inter-Bold" }}
            >
              Getting Started
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              <strong>Search:</strong> To begin, use the search bar at the top
              of the page to enter your search query. You can enter keywords or
              phrases related to the images you're looking for. For example
              scientific plant name, person or place.
              <p>
                <strong>Example Searches:</strong>
              </p>
              <ol>
                <li> Australia - this will search Australia in all columns.</li>
                <li>
                  country:Australia AND recordedBy:Wilson - this will limit the
                  word to search based on specific category.
                </li>
                <li>
                  country:Australia AND recordedBy:Wilson AND
                  scientificName:Pimelea AND (NOT Western)
                </li>
                <li>
                  catalogNumber:(NSW1217, NSW736, NSW121)<br />
                  catalogNumber:(NSW1217*, ???736, NSW1?1)
                </li>
                <li>
                  Wildcards can be used to assist in searching:<br />
                  ? will substitute any single character<br />
                  * will substitute any number of characters
                </li>
                <li>
                  {" "}
                  "1984-11-12" - this will search for this date in all columns{" "}
                </li>
                <li> Radula AND NOT Australia</li>
                <li>
                  "4 km W of Bathurst" - this will search the exact phrase in
                  all categories.
                </li>
                <li>
                  locality: “4 km W of Bathurst”- this will search the exact
                  phrase in specific category.
                </li>
                <li>
                  locality.keyword: “4 km W of Bathurst”- this will search the
                  exact phrase in locality.
                </li>
                <li>
                  image: yes - this will return results that only contain
                  images; image: no - will return results that don't contain
                  images; by default all results with or without images will be
                  returned.
                </li>
                <li>
                  country: China AND image: no - this search will return results
                  where country is China and there are no images.
                </li>
                <li>
                  Note: To search for a collector in the search bar (not
                  Advanced Search) use recordedBy
                </li>
                <li>
                  Note: For large numbers of search results you will see a count
                  of total results found but a limit of 1000 records will be
                  shown (and downloadable). To obtain more than 1000 records
                  please go to Atlas of Living Australia
                  (https://www.ala.org.au/) or contact us at the National
                  Herbarium of NSW (herbarium.nsw@botanicgardens.nsw.gov.au)
                </li>
              </ol>
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <strong>Filter:</strong> If needed, you can apply filters to
              refine your search results further. It is possible to filter on
              any of the columns displayed (using the three dots to the right of
              the column header) or to use the advance search filter located on
              the search bar. For more details on what you can search on review
              the help menu in advance search
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <strong>View Results:</strong> Press the "Enter" key for your
              search and a table view of results will appear. Here you're able
              to view information on your image, download data to csv and
              download images of your choice by selecting each one using the
              checkbox on the left. You also have the option to filter data via
              the table itself.
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <strong>Select Image:</strong> In the search results view, click
              on an image to view image.
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <strong>Data Download:</strong> To obtain data records, or in the
              case of large image searches use this function to download to csv.
              Images can be retrieved via urls in the csv file.
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <strong> Image Download:</strong> To download an individual image
              or multiple images select using checkboxes and click the image
              download button.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UserGuideModel;
