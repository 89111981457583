// Define fields to display in the table
const fieldsToShow = [
  "scientificName",
  "collector",
  "eventDate",
  "country",
  "stateProvince",
  "locality",
  "catalogNumber",
];

export default fieldsToShow;

// Display on the amount of downloads you can bulk download
export const bulkDownloadCount = 20;

// Define key mappings for tootip component
export const keyMappings = {
  total_count: "Total Count",
  images_not_checked: "Images Not Checked",
  images_present: "Images Present",
  images_absent: "Images Absent",
};
