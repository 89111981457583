import React, { useState } from "react";
import { BiHelpCircle } from "react-icons/bi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

/**
 * Component: AdvanceSearchModel
 * Description: This component represents a modal dialog for displaying advanced search help guide.
 * It includes explanations of different search functionalities and options for users.
 */

// Styles for the modal and its content
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "24px",
  boxShadow: 24,
  p: 4,
};

const contentStyle = {
  maxHeight: "400px",
  overflowY: "auto",
};

const AdvanceSearchModel = () => {
  const [open, setOpen] = useState(false);

  // Function to open the modal
  const handleOpen = () => setOpen(true);

  // Function to close the modal
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        className="user-guide-icon"
        style={{
          position: "absolute",
          top: "15px",
          right: "10px",
        }}
      >
        <BiHelpCircle id="guide-icon" size={20} style={{ color: "white" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={contentStyle}>
            <Typography
              variant="h6"
              component="h2"
              style={{ fontFamily: "Inter-Bold" }}
            >
              Advance Search User Guide
            </Typography>

            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              Welcome to the National Herbarium of New South Wales Collection
              Search Platform. This guide will help you understand what advance
              search queries you can make
            </Typography>

            <Typography
              variant="h6"
              component="h2"
              sx={{ mt: 4, fontFamily: "Inter-Bold" }}
            >
              Advance Search Functionality
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              The National Herbarium of New South Wales Collection Search
              Platform provides users with the capability to conduct advance
              searches.
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              Below are the categories available to advance search by:
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <ol>
                <li>
                  <strong>Filter by Catalog Number </strong> Users can filter by
                  Catalog Number for e.g. 12
                </li>
                <li>
                  <strong>Filter by Collection Date </strong> Users can filter
                  by Collection Date for e.g. 2018 or e.g. 20100105
                </li>
                <li>
                  <strong>Filter by Collector </strong> Users can filter by
                  Collector for e.g. Brown, R
                </li>
                <li>
                  <strong>Filter by Collector Number </strong> Users can filter
                  by Collector Number for e.g. 1200
                </li>
                <li>
                  <strong>Filter by Country </strong> Users can filter by
                  Country for e.g. Australia
                </li>
                <li>
                  <strong>Filter by Det Date</strong> Users can filter by Date
                  Identified for e.g. 1998-07
                </li>
                <li>
                  <strong>Filter by Determiner</strong> Users can filter by the
                  Determiner for e.g. Williamson, H.B.
                </li>
                <li>
                  <strong>Filter by Family</strong> Users can filter by Family
                  for e.g. Rhamnaceae
                </li>
                <li>
                  <strong>Filter by Genus</strong> Users can filter by Genus for
                  e.g. Pomaderris
                </li>
                <li>
                  <strong>Filter by Habitat</strong> Users can filter by Habitat
                  for e.g. Growing on side of rocky gullies, running down the
                  side of the mount.
                </li>
                <li>
                  <strong>Filter by Habitat and Notes</strong> Users can filter
                  by Habitat and Notes for e.g. Fallen
                </li>
                <li>
                  <strong>Filter by Infraspecies</strong> Users can filter by
                  Infraspecies for e.g. Astrophytum myriostigma subvar
                </li>
                <li>
                  <strong>Filter by Locality</strong> Users can filter by
                  Locality for e.g. Coxs River.
                </li>
                <li>
                  <strong>Filter by Native</strong> Users can filter by Native
                  for e.g. Introduced
                </li>
                <li>
                  <strong>Filter by Reproductive Status</strong> Users can
                  filter by Reproductive Status for e.g. Sterile
                </li>
                <li>
                  <strong>Filter by Species</strong> Users can filter by Species
                  for e.g. paniculosa
                </li>
                <li>
                  <strong>Filter by State/Province</strong> Users can filter by
                  State/Province for e.g. Queensland
                </li>
                <li>
                  <strong>Filter by Subdivision/County</strong> Users can filter
                  by Subdivision/County for e.g. Zimmerman
                </li>
                <li>
                  <strong>Filter by Type</strong> User can search by type e.g.
                  PhysicalObject
                </li>
              </ol>
            </Typography>
            <Typography sx={{ mt: 2, fontFamily: "Inter-Medium" }}>
              <strong>Additional Features</strong>
            </Typography>
            <Typography sx={{ mt: 1, fontFamily: "Inter-Medium" }}>
              <ol>
                <li>
                  <strong>Search Suggestions: </strong> The suggestions are
                  provided when you start typing.
                </li>
                <li>
                  <strong>Exact Search: </strong> There is an option to filter
                  by exact term by using the "" . For example if you want to
                  search a collector Brown, R and you only want Brown, R to
                  appear in search you can wrap it in "Brown,R" and the results
                  will only show the exact match. Otherwise without quotes the
                  top results will show Brown,R but further pages will show
                  other results starting with Brown or R.
                </li>
              </ol>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AdvanceSearchModel;
