import { FaSearch } from "react-icons/fa";
import { useSearchContext } from "../../helper/context/SearchTermContext";
import IconButton from "@mui/material/IconButton";
import AdvancedSearchDialog from "../AdvanceSearch/AdvanceSearch";
import "./SearchBar.css";

/**
 * Component: SearchBar
 * Description: This component represents a search bar with an input field and search icon.
 * It includes options for advanced search and dynamic placeholder text.
 * @param {Function} handleOnChange - Function to handle input change event
 * @param {Function} handleKeyDown - Function to handle key down event
 * @param {Function} handleSubmit - Function to handle form submission event
 */

const SearchBar = ({ handleOnChange, handleKeyDown, handleSubmit }) => {
  // Placeholder options for the search input
  const placeholderOptions = ["Search for Plant, Person, Place"];

  const { searchTerm } = useSearchContext();

  return (
    <>
      <form className="search-bar" onSubmit={handleSubmit}>
        <IconButton type="submit" className="search-button">
          <FaSearch id="search-icon" size={18} />
        </IconButton>
        <input
          type="text"
          placeholder={placeholderOptions}
          value={searchTerm}
          className="search-input"
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        <AdvancedSearchDialog />
      </form>
    </>
  );
};

export default SearchBar;
