import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { CSVLink } from "react-csv";
import { PiFileCsv } from "react-icons/pi";
import { CiImageOn } from "react-icons/ci";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { bulkDownloadCount } from "../../helper/constants";
import MuiPagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import {
  Magnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";
import { useAdvancedSearchHitsContext } from "../../helper/context/AdvanceSearchHitsContext";
import config from "../../helper/config";
import fieldsToShow from "../../helper/constants";

import "./SearchResult.css";

/**
 * Component: SearchResult
 * Description: Displays search results in a table with pagination and download options.
 * It renders a table with columns for various fields such as scientific name, collector, date, etc.
 * The component provides functionalities like pagination, downloading selected data as CSV, and bulk downloading data.
 * It also includes features like rendering images in a dialog when clicked, and customizing column headers for better readability.
 * @param {Array} result - Array of search results to be displayed in the table
 */

export default function SearchResult({ result, totalHits }) {
  // Extract headers from the first item in the result array
  let headers = [];
  const uuid = require("uuid");
  // Generate timestamp in the format YY-MM-DD-HH-MM-SS
  const timestamp = new Date().toISOString().slice(2, 19).replace(/[-:]/g, "");
  // Generate a UUID
  const uniqueId = uuid.v4();
  // Combine timestamp and UUID to create filename
  const filename = `${timestamp}-${uniqueId}.csv`;

  if (result && Array.isArray(result) && result.length > 0) {
    headers = [
      ...Object.keys(result[0]), // Include all original headers
      "image_url", // Add image_url as a new header,
    ];
  }

  // Function to convert field names to more appealing header names
  const headerToHeaderName = (header) => {
    switch (header) {
      case "scientificName":
        return "Scientific Name";
      case "collector":
        return "Collector";
      case "eventDate":
        return "Date";
      case "country":
        return "Country";
      case "stateProvince":
        return "State/Province";
      case "locality":
        return "Locality";
      case "catalogNumber":
        return "Catalog Number";

      default:
        return header;
    }
  };

  const bulkApiUrl = config.APIUrl + "bulk";

  // Function to render event date cell with formatted date
  const renderEventDateCell = (params) => {
    const formattedDate = formatDate(params.value);
    return <span>{formattedDate}</span>;
  };
  // Define columns for the table
  const columns = fieldsToShow.map((header) => ({
    field: header,
    headerName: headerToHeaderName(header),
    width: 190,
    renderCell: header === "eventDate" ? renderEventDateCell : undefined,
  }));

  // Add a custom rendering function for the image_url column
  columns.forEach((column) => {
    if (column.field === "image_url") {
      column.renderCell = (params) => {
        return (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        );
      };
    }
  });
  // Create columns with custom cell renderer for collector column
  columns.forEach((column) => {
    if (column.field === "collector") {
      column.renderCell = (params) => {
        return <span>{params.value}</span>;
      };
    }
  });

  // Concatenate collector name and record number
  const concatenateCollector = (params) => {
    const recordedBy = params.recordedBy ?? "";
    const recordNumber = params.recordNumber ?? "";

    return `${recordedBy}  ${recordNumber}`;
  };

  // Format date string to a more readable format
  const formatDate = (dateString) => {
    const timestamp = Date.parse(dateString);

    if (!isNaN(timestamp)) {
      const date = new Date(timestamp);
      return date.toLocaleDateString(undefined, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    } else {
      return dateString; // Return the original string if it cannot be parsed
    }
  };

  // Transform result data into rows for the table
  const rows = result.map((item, index) => {
    // Generate image URL based on catalogNumber
    const imageURL = `${config.ImageUrl}${item.catalogNumber.replace(
      /\s/g,
      ""
    )}.jpg`;

    const collector = concatenateCollector(item);
    const eventDateFormatted = formatDate(item.eventDate);

    return {
      // id: index + 1,
      id: item._id,
      image_url: item.image === "yes" ? imageURL : null, // Set image_url to null if image is not "yes"
      collector: collector,
      eventDate: eventDateFormatted,
      ...item,
    };
  });

  // State variables
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalRows, setTotalRows] = useState(rows?.length);
  const [isLimitExceededDialogOpen, setIsLimitExceededDialogOpen] =
    useState(false);
  const { totalHitsAdvanced } = useAdvancedSearchHitsContext();

  // Update totalRows state when rows change
  useEffect(() => {
    setTotalRows(rows?.length);
  }, [rows]);

  // Custom Pagination component
  const Pagination = ({ page, onPageChange, className }) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        className={className}
        count={pageCount}
        page={page + 1}
        boundaryCount={1} // Display 1 item at the beginning and end
        siblingCount={2}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  };

  const CustomPagination = (props) => {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  };

  // ActionItems - Renders custom actions for each row - holds the image for each row
  const ActionItems = (params) => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const handleImageClick = (event, image) => {
      event.stopPropagation();
      setSelectedImage(image);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const saveImage = () => {
      const link = document.createElement("a");
      link.href = selectedImage;
      link.target = "_blank"; // Open link in a new tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        {params.row.image === "yes" ? (
          <IconButton
            onClick={(event) => handleImageClick(event, params.row.image_url)}
          >
            <CiImageOn />
          </IconButton>
        ) : null}
        {/* Dialog for displaying enlarged image */}
        <Dialog open={open} onClose={handleClose}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogActions>
            <Tooltip title="Open Image">
              <IconButton onClick={saveImage} color="primary">
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <DialogContent>
            <Magnifier
              imageSrc={selectedImage}
              mouseActivation={MOUSE_ACTIVATION.CLICK}
              touchActivation={TOUCH_ACTIVATION.TAP}
              style={{ width: "95%", height: "95%" }}
              dragToMove={true}
              interactionSettings={{ width: 100, height: 100 }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  // Function to handle download of selected data as CSV
  const handleDownloadSelected = () => {
    // Prepare selected data for CSV export
    const selectedData = selectedRows.map((row) => ({
      ...row,
      image_url: row.image_url,
    }));
    // Prepare CSV data
    const csvData = selectedData.map((row) => {
      const csvRow = {};
      headers.forEach((header) => {
        csvRow[header] = row[header];
      });
      return csvRow;
    });

    const csvOptions = {
      filename: filename,
      headers,
    };

    // Render CSVLink component to trigger download
    return (
      <CSVLink data={csvData} {...csvOptions}>
        Download Selected Data
      </CSVLink>
    );
  };
  // Function to handle bulk download of data
  const handleBulkDownload = () => {
    // Extracting IDs from selected rows
    const ids = selectedRows.map((row) => String(row._id).replace(/\D/g, ""));

    if (selectedRows.length > bulkDownloadCount) {
      setIsLimitExceededDialogOpen(true);
      return;
    }

    // Perform bulk download via API
    fetch(bulkApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ids: ids }),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          window.location.href = data.url; // Trigger the file download
        }
      })
      .catch((error) => {
        console.error("Error performing bulk download:", error);
      });
  };

  // Function to close the limit exceeded dialog
  const handleCloseLimitExceededDialog = () => {
    setIsLimitExceededDialogOpen(false);
  };

  return (
    <>
      <div className="table-header-container">
        <div className="download-csv-button-container">
          <CSVLink
            data={selectedRows}
            onClick={handleDownloadSelected}
            filename={filename}
            headers={headers}
            className="download-link"
            target="_blank"
          >
            <Tooltip title="Data Download will download all selected data to CSV this will contain all headings">
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#243b2f ",
                  color: "#FFFFF5",
                  "&:hover": {
                    backgroundColor: "#243b2f ",
                  },
                  marginTop: "10px",
                  fontFamily: "Inter-Medium",
                }}
              >
                Data Download
                <PiFileCsv
                  id="guide-icon"
                  size={20}
                  style={{ color: "#FFFFF5", marginBottom: "3px" }}
                />
              </Button>
            </Tooltip>
          </CSVLink>
        </div>
        <div className="download-zip-button-container">
          <Tooltip title="Image Download will download images of selected items to zip ">
            <Button
              variant="contained"
              onClick={handleBulkDownload}
              sx={{
                borderRadius: "20px",
                backgroundColor: "#243b2f ",
                color: "#FFFFF5",
                "&:hover": {
                  backgroundColor: "#243b2f ",
                },
                marginTop: "10px", // Add spacing between the grid and the button
                fontFamily: "Inter-Medium",
              }}
            >
              Image Download
              <CiImageOn
                id="guide-icon"
                size={20}
                style={{ color: "#FFFFF5", marginBottom: "3px" }}
              />
            </Button>
          </Tooltip>
        </div>
        <div className="found-result">
          {totalRows === 0
            ? "Found 0 results"
            : `Found ${
                totalHits > 0 ? totalHits : totalHitsAdvanced
              } showing ${totalRows} results`}
        </div>
      </div>
      <div
        className="table-container"
        style={{ height: "850px", width: "90%" }}
      >
        <DataGrid
          getRowHeight={() => "auto"}
          rows={rows}
          columns={[
            {
              field: "image",
              headerName: "Image",
              width: 160,
              renderCell: ActionItems,
            },
            ...columns,
          ]}
          pagination
          slots={{
            pagination: CustomPagination,
          }}
          showToolBar
          {...rows}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 50 } },
          }}
          pageSize={[10, 20]}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = rows.filter((row) => selectedIDs.has(row._id));

            setSelectedRows(selectedRows);
          }}
          {...result}
        />
        {/* Dialog for showing limit exceeded message */}
        <Dialog
          open={isLimitExceededDialogOpen}
          onClose={handleCloseLimitExceededDialog}
        >
          <DialogTitle style={{ color: "#243b2f", fontFamily: "Inter-Medium" }}>
            Items Selected Exceeded Limit for Image Download
          </DialogTitle>
          <DialogContent style={{ fontFamily: "Inter-Medium" }}>
            <p>You have selected more than {bulkDownloadCount} items.</p>
            <p>Please consider Data Download instead.</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseLimitExceededDialog}
              sx={{ color: "#243b2f" }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
