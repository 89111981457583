import React, { useEffect, useState } from "react";
import { useItemDataContext } from "../../helper/context/ItemDataContext";
import { useSearchContext } from "../../helper/context/SearchTermContext";
import SearchResult from "../../components/SearchResult/SearchResult";
import NavBarSearch from "../../components/NavBarSearch/NavBarSearch";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../helper/config";
import "./SearchResultPage.css";

/**
 * SearchResultPage Component
 * Description: This component represents the page displaying search results.
 * It fetches item data based on the search term from the API and passes it to the SearchResult component.
 */

const SearchResultPage = () => {
  const { itemData, setItemData } = useItemDataContext(); // Access item data context
  const { searchTerm } = useSearchContext();
  const [isLoading, setIsLoading] = useState(true);
  const [totalHits, setTotalHits] = useState(0);

  /**
   * Function to fetch item data from the API based on the search term
   */
  useEffect(() => {
    const fetchItemData = async () => {
      // Construct the API URL for search
      const apiUrl = config.APIUrl + "search";

      try {
        // Send a GET request to the API with the search term as a query parameter
        const response = await fetch(`${apiUrl}?q=${searchTerm}`);

        if (!response.ok) {
          throw new Error("Failed to fetch item data");
        }
        // Parse the JSON response body
        const data = await response.json();

        // Set item data if search results are found
        if (data.hits.total.value >= 0) {
          // setItemData(data.hits.hits.map((hit) => hit._source));
          setItemData(
            data.hits.hits.map((hit) => {
            return {
              ...hit._source,
                _id: hit._id,
            };
            })
          );
          setTotalHits(data.hits.total.value);
        } else {
          setItemData([]);
          setTotalHits(0);
        }
      } catch (error) {
        // Catch and handle any errors that occur during the fetch process
        console.error("Error fetching item data:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching data
      }
    };
    // Fetch item data when searchTerm changes
    if (searchTerm) {
      setIsLoading(true); // Set loading state to true before fetching data
      fetchItemData(searchTerm);
    }
  }, [searchTerm, setItemData]); //Dependency array to trigger effect on searchTerm change

  return (
    <>
      <NavBarSearch />

      {isLoading && searchTerm ? (
        <div className="loading-icon">
          <CircularProgress color="success" />
        </div>
      ) : (
        itemData && <SearchResult result={itemData} totalHits={totalHits} />
      )}
    </>
  );
};

export default SearchResultPage;
