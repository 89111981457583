import React, { createContext, useContext, useState, useEffect } from "react";

// Create context for search term
const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState(() => {
    // Retrieve search term from localStorage on component mount
    const storedSearchTerm = localStorage.getItem("searchTerm");
    return storedSearchTerm || ""; // If no searchTerm found, default to empty string
  });

  // Update searchTerm in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("searchTerm", searchTerm);
  }, [searchTerm]);

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
