import React, { createContext, useContext, useState, useEffect } from "react";

/**
 * Component: ItemDataProvider
 * Description: This component defines a context and provider for managing item data state across the application.
 *              It initializes item data from localStorage and updates localStorage whenever item data changes.
 *              The ItemDataContext context and useItemDataContext hook allow consuming components to access the item data.
 * @param {ReactNode} children - The child components to be wrapped by the provider.
 */

// Create a context for item data
const ItemDataContext = createContext();
// Custom hook to access item data context
export const useItemDataContext = () => useContext(ItemDataContext);

// Provider component to manage item data state
export const ItemDataProvider = ({ children }) => {
  // State to manage item data
  const [itemData, setItemData] = useState(() => {
    try {
      // Attempt to read data from localStorage
      const storedData = localStorage.getItem("itemData");
      // Parse the data, or return null if parsing fails
      return storedData ? JSON.parse(storedData) : null;
    } catch (error) {
      // Log the error
      console.error("Error parsing item data from localStorage:", error);
      // Return null if parsing fails
      return null;
    }
  });

  // Effect to save item data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("itemData", JSON.stringify(itemData));
  }, [itemData]);

  // Provide item data context to children components
  return (
    <ItemDataContext.Provider value={{ itemData, setItemData }}>
      {children}
    </ItemDataContext.Provider>
  );
};
