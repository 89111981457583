import React, { useState, useEffect } from "react";
import UserGuideModel from "../../components/UserGuideModel/UserGuideModel";
import ImageData from "../ImageData/ImageData";
import config from "../../helper/config";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { keyMappings } from "../../helper/constants";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

import "./NavBarSearch.css";

/**
 * Component: NavBarSearch
 * Description: This component represents the navigation bar with a search feature.
 * It includes the RBG logo, a search input, and a user guide icon.
 * This component is specific for the searchResultPage Component
 */

const NavBarSearch = () => {
  const [healthData, setHealthData] = useState({});
  const apiUrl = config.APIUrl + "health";

  useEffect(() => {
    const fetchHealthData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setHealthData(data);
      } catch (error) {
        console.error("Error fetching health data:", error);
        setHealthData([]);
      }
    };

    // Initial fetch
    fetchHealthData();

    // Set interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchHealthData, 300000); // 300000 ms = 5 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiUrl]); // Run effect whenever apiUrl changes

  return (
    <nav className="navSearch">
      <Link to="/" className="rbg-logo">
        <img
          src="/images/BGS_Logo_Inline_Green-Light_RGB@2x.png"
          alt="Logo"
          className="navbar-logo"
        />
      </Link>
      <div className="image-data-container">
        <ImageData />
      </div>
      <div className="icon-data-container">
        <div className="data-base-stats">
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Tooltip
              title={
                <Paper sx={{ p: 1 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, fontFamily: "Inter-Bold" }}
                  >
                    Database Stats
                  </Typography>
                  {Object.keys(healthData).length > 0 ? (
                    Object.keys(healthData).map((key, index) => (
                      <Typography
                        key={index}
                        sx={{ mb: 1.5, fontFamily: "Inter-Medium" }}
                      >
                        <strong> {keyMappings[key] || key}:</strong>
                        {` ${JSON.stringify(healthData[key], null, 2)}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography>No Database Stats Available</Typography>
                  )}
                </Paper>
              }
              arrow
            >
              <InfoOutlinedIcon size={20} sx={{ p: 1, color: "white" }} />
            </Tooltip>
          </Box>
        </div>
        <div className="user-guide-icon">
          <UserGuideModel />
        </div>
      </div>
    </nav>
  );
};

export default NavBarSearch;
