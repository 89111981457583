import React, { useDeferredValue, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchContext } from "../../helper/context/SearchTermContext";
import { useAdvancedSearchContext } from "../../helper/context/AdvanceSearchContext";
import SearchBar from "../../components/SearchBar/SearchBar";
import config from "../../helper/config";

/**
 * Component: ImageData
 * Description: This component manages the search functionality and displays the search results.
 * It includes a search bar for inputting search terms and a list to display search results.
 */

export const ImageData = () => {
  // State variables to manage search term and image data
  const { searchTerm, setSearchTerm } = useSearchContext();
  const [imagesData, setImageData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(-1);
  const { setAppliedFilters } = useAdvancedSearchContext();

  // useDeferredValue hook to get a deferred version of the search term
  const deferredInput = useDeferredValue(searchTerm);

  const navigate = useNavigate();
  const location = useLocation();
  //useEffect hook to update the searchTerm to empty if it's not the result page
  useEffect(() => {
    if (!location.pathname.startsWith("/result")) {
      setSearchTerm("");
    }
  }, [location.pathname, setSearchTerm]);
  //useEffect hook to fetch image data when the search term changes
  useEffect(() => {
    if (deferredInput !== "") {
      // Only fetch if search term is not empty
      fetchImageData(deferredInput).then((imageData) => {
        setImageData(imageData); // Update image data with fetched results
      });
    } else {
      setImageData([]); // Clear image data if search term is empty
    }
  }, [deferredInput]); // useEffect will run whenever deferredInput changes

  // Function to fetch image data from the API
  const fetchImageData = (value) => {
    const apiUrl = config.APIUrl + "search";

    return fetch(`${apiUrl}?q=${value}`)
      .then((res) => res.json())
      .then((response) => {
        return response.hits.hits;
      })
      .catch((error) => {
        console.error("Error fetching image data:", error);
        return [];
      });
  };

  // Event handler to update search term when input changes
  const handleOnChange = (e) => {
    setSearchTerm(e.target.value); // Update search term based on input value
  };

  // Event handler for keydown events
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && selectedItem >= 0 && imagesData.length > 0) {
      if (
        imagesData[selectedItem] &&
        imagesData[selectedItem].id &&
        imagesData[selectedItem].id.show &&
        imagesData[selectedItem].id.show.url
      ) {
        window.open(imagesData[selectedItem].id.show.url);
      }
    }
  };
  // Define a function to handle form submission
  const handleSubmit = (e) => {
    // Prevent the default form submission behavior
    e.preventDefault();
    // Check if the search term is not empty after removing leading and trailing whitespace
    if (searchTerm.trim() !== "") {
      // Check if there is any image data available
      if (imagesData.length > 0) {
        // If there is image data available, navigate to the search result page with the encoded search term as a path parameter
        setAppliedFilters([]);
        navigate(`/result/${searchTerm}`);
      } else {
        // If there is no image data available, navigate to the search result page with the encoded search term as a path parameter
        navigate(`/result/${searchTerm}`);
      }
    }
  };

  return (
    <div className="imageData">
      {/* SearchBar component to input search term */}
      <SearchBar
        handleOnChange={handleOnChange}
        handleKeyDown={handleKeyDown}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ImageData;
