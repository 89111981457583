import React, { createContext, useContext, useEffect, useState } from "react";
const AdvancedSearchContext = createContext();

export const useAdvancedSearchContext = () => useContext(AdvancedSearchContext);

export const AdvancedSearchProvider = ({ children }) => {
  // State to manage applied filters
  const [appliedFilters, setAppliedFilters] = useState(() => {
    try {
      // Attempt to read data from localStorage
      const storedFilters = localStorage.getItem("appliedFilters");
      // Parse the data, or return an empty array if parsing fails
      return storedFilters ? JSON.parse(storedFilters) : [];
    } catch (error) {
      // Log the error
      console.error("Error parsing applied filters from localStorage:", error);
      // Return an empty array if parsing fails
      return [];
    }
  });

  // Effect to save applied filters to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("appliedFilters", JSON.stringify(appliedFilters));
  }, [appliedFilters]);

  return (
    <AdvancedSearchContext.Provider
      value={{ appliedFilters, setAppliedFilters }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  );
};
